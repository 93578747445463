﻿/*Stylesheet for the CCPA Dsa App*/

/*#region MainBody*/
body {
    font-family: Lato, Corbel, ‘Lucida Grande’, ‘Lucida Sans Unicode’, ‘DejaVu Sans’, ‘Bitstream Vera Sans’, ‘Liberation Sans’, Verdana, ‘Verdana Ref’, sans-serif;
    font-size: 12px;
}

.mainBody {
    font-weight: bold;
    color: #ABAFB3;
    max-width: 1250px;
    margin: auto;
}

.headerContainer {
    margin: 20px 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
}

.footerContainer {
    text-align: center;
    text-transform: uppercase; 
}

.roundedCornersCard {
    border-radius: 4px;
}

.thirdPageWidth {
    width: calc(66.66667% - 24px);
}

.paddingCopyJsonButton {
    padding: 0 42px;
}

.tooltip {
    position: relative;
    display: inline-block;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    height: 22px;
    width: 22px;
    background-color: #8C9094;
    border-radius: 50%;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #FFFFFF;
    color: #6D747A;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-weight: 400;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    box-shadow: 0 4px 8px 0 rgba(42,52,61,.16);
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip .tooltiptext {
    top: -5px;
    left: 115%;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent white transparent transparent;
}

.tooltipReport {
    position: relative;
}

.tooltipReport .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #FFFFFF;
    color: #6D747A;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-weight: 400;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    box-shadow: 0 4px 8px 0 rgba(42,52,61,.16);
}

.tooltipReport:hover .tooltiptext {
    visibility: visible;
}

.tooltipReport .tooltiptext {
    top: -5px;
    left: 115%;
}

.tooltipReport .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent white transparent transparent;
}

.font14 {
    font-size: 14px;
}

.uploadSeparator {
    border: 1px solid #8C9196;
}

.borderRadius4 {
    border-radius: 4px;
}

.offScreenJsonCopy {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.mdc-dialog__surface {
    max-height: 400px;
    overflow-y: scroll;
}

.discloseRequestHeight {
    height: 30px;
}

.floatRight {
    float: right;
}


.categoryBottomBorder {
    border-bottom: 1px solid #8C9196;
}

.pointer {
    cursor: pointer;
}

.sortArrowSize {
    max-width: 12px;
}

.sortArrowContainer {
    margin-top: 2px;
    margin-left: 4px;
}

/*#endregion MainBody*/

/*#region Styles*/
.errorText {
    color: #ff0000;
}
/*#endregion Styles*/

/*#region PrintStyles*/
.headerContainerPrint {
    margin: 20px 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: Column;
}

.categoryRow {
    background-color: #FFFFFF;
}

.categoryRow:nth-child(even) {
    background-color: #FAFAFA;
}

.displayFlexRow {
    display: flex;
    flex-direction: row;
}

.displayFlexColumn {
    display: flex;
    flex-direction: column;
}
.categoriesKeyValues {
    width: 90%;
    border: 1px solid #000000;
}
.fullWidth{
    width: 100%;
}

.font16 {
    font-size: 16px;
}

.font12 {
    font-size: 12px;
}

.font10 {
    font-size: 10px;
}

.font8 {
    font-size: 8px;
}

@media print {
    #dataPoints {
        page-break-inside: avoid;
    }
}

@page {
    size: auto;
    margin-bottom: 0;
}

.tableDisclaimer {
    page-break-before: always;
}

.mailingAddress {
    padding-left: 55px;
    font-family: CarMaxSharpSansDisp, Frutiger, ‘Frutiger Linotype’, Univers, Calibri, ‘Gill Sans’, ‘Gill Sans MT’, ‘Myriad Pro’, Myriad, ‘DejaVu Sans Condensed’, ‘Liberation Sans’, ‘Nimbus Sans L’, Tahoma, Geneva, ‘Helvetica Neue’, Helvetica, Arial, sans-serif;
    font-weight: 400;
    margin-top: -20px;
}

/*#endregion PrintStyles*/
